
.comment-words {
  .item-header {
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 18px;
    color: #000000;
    text-align: left;
    font-style: normal;
  }

  .item-chart {
    width: 100%;
    min-height: calc(100vh - 490px);
    box-sizing: border-box;
    background-color: #fff;
    padding-top: 24px;

    .chart-content {
      height: 320px;
      background-color: #f7f9fb;
      border-radius: 8px;
      padding: 24px;
      .header {
        display: flex;
        align-items: center;
        position: relative;

        &-type {
          margin-right: 10px;
          span {
            margin-right: 10px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 12px;
            color: #777e87;
            line-height: 14px;
            text-align: right;
            font-style: normal;
          }
        }
      }
    }

    .chart {
      height: 300px;
      margin-top: 10px;
      // border: 1px solid black;
    }
  }

  .chart-no-data {
    width: 100%;
    min-height: calc(100vh - 490px);
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: rgb(201, 202, 202);
      text-align: center;
      font-style: normal;
    }
  }
}
