
.content-item {
  .item-header {
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 18px;
    color: #000000;
    text-align: left;
    font-style: normal;
  }

  .item-buttons {
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 15px;

    .button {
      width: 215px;
      height: 80px;
      background-color: #f7f9fb;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 16px 24px;
      cursor: pointer;

      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #777e87;
      line-height: 14px;
      text-align: left;
      font-style: normal;

      .button-value {
        font-size: 16px;
        color: #111111;
        line-height: 19px;
        margin-top: 3px;
      }

      &.is-active {
        background-color: #594fee;

        & > .button-label,
        & > .button-value {
          color: #fff;
        }
      }
    }
  }

  .item-chart {
    width: 100%;
    min-height: calc(100vh - 570px);
    box-sizing: border-box;
    background-color: #fff;
    padding-top: 24px;

    .chart-content {
      height: 320px;
      background-color: #f7f9fb;
      border-radius: 8px;
      padding: 24px;
      .header {
        display: flex;
        align-items: center;
        position: relative;

        &-type {
          margin-right: 10px;
          span {
            margin-right: 10px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #777e87;
            line-height: 14px;
            text-align: left;
            font-style: normal;
          }

          ::v-deep .el-input__prefix {
            position: relative;
            left: 0;
            box-sizing: border-box;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #777e87;
            line-height: 14px;
            text-align: left;
            font-style: normal;
            cursor: pointer;
          }

          ::v-deep .el-input__suffix {
            margin-left: 3px;
            position: relative !important;
          }

          ::v-deep .el-input__inner {
            width: max-content;
            width: 0;
            height: 25px;
            border: none;
            padding: 0;
          }

          ::v-deep .el-input__icon {
            line-height: 25px;
          }
        }

        &-date {
          ::v-deep .el-range-editor.el-input__inner {
            background-color: #f2f2f4;
          }
          ::v-deep .el-date-editor .el-range-input {
            background-color: transparent;
          }

          ::v-deep .el-date-editor--daterange.el-input,
          .el-date-editor--daterange.el-input__inner,
          .el-date-editor--timerange.el-input,
          .el-date-editor--timerange.el-input__inner {
            width: 250px;
          }
        }

        &-export {
          position: absolute;
          right: 0px;
          top: 0px;
          font-family: PingFang SC, PingFang SC;
          color: #594fee;
          font-size: 12px;
          font-weight: 400;
          // line-height: 14px;
          cursor: pointer;

          .export-icon {
            font-size: 14px;
            margin-right: 4px;
          }
        }
      }
    }

    .chart {
      height: 280px;
      margin-top: 10px;
      // border: 1px solid black;
    }
  }
}
